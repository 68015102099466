@import "../abstracts/variables";
@import "../abstracts/mixins";

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

html {
  // Defines 1 rem value = 10px; 10px/16px = 62.5%
  font-size: 62.5%;

  @include respond(big-desktop) {
    font-size: 75%; // 1rem = 12px; 12/16 = 75%
  }

  @include respond(tab-land) {
    font-size: 56.25%; // 1rem = 9px; 9/16 = 56.25%
  }

  @include respond(tab-port) {
    font-size: 50%; // 1rem = 8px; 8/16 = 50%
  }

  @include respond(phone) {
    font-size: 50%;
  }
}

body {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

::selection {
  background-color: $color-primary;
  color: $color-white;
}