@import "../abstracts/variables";
@import "../abstracts/mixins";

.navigation {

  &__checkbox {
    display: none;
  }

  &__button {
    background-color: $color-white;
    height: 5rem;
    width: 5rem;
    position: fixed;
    top: 3rem;
    right: 4rem;
    border-radius: 50%;
    z-index: 1001;
    box-shadow: 0 1rem 3rem rgba($color-black, .2);
    text-align: center;
    cursor: pointer;

    //@include respond(phone) {
    //  height: 5rem;
    //  width: 5rem;
    //  position: fixed;
    //  top: 3rem;
    //  right: 2rem;
    //}
  }

  &__background {
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    position: fixed;
    top: 3.5rem;
    right: 4.5rem;
    background-image: radial-gradient($color-primary-light, $color-primary-dark);
    z-index: 999;
    transition: transform .7s cubic-bezier(0.86, 0, 0.07, 1);
  }

  &__nav {
    height: 100vh;
    width: 0;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1000;
    opacity: 0;
    transition: all .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  &__list {
    @include center-element;
    list-style: none;
    text-align: center;
    width: 100%;
  }

  &__item {
    margin: 1rem;
  }

  &__link {

    &:link, &:visited {
      display: inline-block;
      font-size: 3rem;
      font-weight: 300;
      padding: 1rem 2rem;
      color: $color-white;
      text-decoration: none;
      text-transform: uppercase;
      background-image: linear-gradient(120deg, transparent 0%, transparent 50%, $color-white 50%);
      background-size: 220%;
      transition: all .4s;

      span {
        display: inline-block;
        margin-right: 1.5rem;
      }
    }

    &:hover, &:active {
      background-position: 100%;
      color: $color-primary;
      transform: translateX(1rem);
    }
  }

  // FUNCTIONALITY

  &__checkbox:checked ~ &__nav {
    opacity: 1;
    width: 100vw;
  }

  &__checkbox:checked ~ &__background {
    transform: scale(80);
  }

  // ICON

  &__icon {
    position: relative;
    margin-top: 2.5rem;

    &, &::before, &::after {
      width: 2rem;
      height: 2px;
      background: $color-grey-dark-3;
      display: inline-block;
    }

    &::before, &::after {
      content: "";
      position: absolute;
      left: 0;
      transition: all .2s;
    }

    &::before {
      top: -.7rem;
    }

    &::after {
      top: .7rem;
    }
  }

  &__button:hover &__icon::before {
    top: -.9rem;
  }

  &__button:hover &__icon::after {
    top: .9rem;
  }

  &__checkbox:checked + &__button &__icon {
    background-color: transparent;
  }

  &__checkbox:checked + &__button &__icon::before {
    transform: rotate(135deg);
    top: 0;
  }

  &__checkbox:checked + &__button &__icon::after {
    transform: rotate(-135deg);
    top: 0;
  }
}