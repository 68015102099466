@import "../abstracts/variables";
@import "../abstracts/mixins";

.footer {
  background-color: $color-grey-dark-3;
  padding: 10rem 0;
  font-size: 1.4rem;

  &__logo-box {
    text-align: center;
    margin-bottom: 8rem;

    @include respond(tab-port) {
      margin-bottom: 4rem;
    }

    @include respond(phone) {
      margin-bottom: 4rem;
    }
  }

  &__logo {
    width: 15rem;
    height: auto;

    @include respond(phone) {
      width: 10rem;
    }
  }

  &__navigation {
    border-top: 1px solid $color-grey-dark-1;
    padding-top: 2rem;
    display: inline-block;

    @include respond(tab-port) {
      width: 100%;
      display: block;
      text-align: center;
    }

    @include respond(phone) {
      width: 100%;
      display: block;
      text-align: center;
    }
  }

  &__list {
    list-style: none;
  }

  &__item {
    display: inline-block;

    &:not(:last-child) {
      margin-right: 2rem;
    }
  }

  &__link {

    &:link, &:visited {
      color: $color-grey-light-1;
      background-color: $color-grey-dark-3;
      text-decoration: none;
      text-transform: uppercase;
      display: inline-block;
      transition: all .2s;
    }

    &:hover, &:active {
      box-shadow: 0 2rem 4rem rgba($color-black, .4);
      color: $color-primary;
      transform: scale(1.2);
    }
  }

  &__copyright {
    border-top: 1px solid $color-grey-dark-1;
    padding-top: 2rem;
    display: inline-block;
    letter-spacing: 1px;
    width: 82%;
    float: right;

    @include respond(tab-port) {
      width: 100%;
      display: block;
      text-align: center;
    }

    @include respond(phone) {
      width: 100%;
      display: block;
      text-align: center;
    }
  }
}
