@import "../abstracts/variables";
@import "../abstracts/mixins";

.feature-box {
  background-color: rgba($color-white, .8);
  font-size: 1.5rem;
  padding: 2.5rem;
  text-align: center;
  border-radius: 3px;
  box-shadow: 0 1.5rem 4rem rgba($color-black, .2);
  transition: transform .2s;

  @include respond(tab-port) {
    margin: 0 auto;
    width: 60%;
  }

  & .heading-tertiary {
    font-size: 1.5rem;
  }

  &__icon {
    font-size: 6rem;
    margin-bottom: .5rem;
    display: inline-block;
    background: linear-gradient(to right, $color-primary-light, $color-primary-dark);
    -webkit-background-clip: text;
    color: transparent;
  }

  &:hover{
    transform: translateY(-1.5rem) scale(1.05);
    box-shadow: 0 2.5rem 4rem rgba($color-black, .2);
  }
}
