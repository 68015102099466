@import "../abstracts/variables";

.btn {
  &, &:link, &:visited {
    text-transform: uppercase;
    text-decoration: none;
    padding: 1.5rem 4rem;
    display: inline-block;
    border-radius: 100px;
    transition: all .2s;
    position: relative;
    font-size: $default-font-size;

    // Change for the <button> element
    border: none;
    cursor: pointer;
  }

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 1rem 2rem rgba($color-black, .2);

    //&::after {
    //  transform: scaleX(1.5) scaleY(1.6);
    //  opacity: 0;
    //}
  }

  &:active, &:focus {
    outline: none;
    transform: translateY(-1px);
    box-shadow: 0 .5rem 1rem rgba($color-black, .2);
  }

  &--white {
    background-color: white;
    color: $color-grey-dark-1;

    &::after {
      background-color: $color-white;
      color: $color-grey-dark-1;
    }
  }

  &--green {
    background-color: $color-primary;
    color: $color-white;

    &::after {
      background-color: $color-primary;
      color: $color-white;
    }
  }

  &::after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 10rem;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all .5s;
  }

  &--animated {
    animation: moveInBottom .5s ease-out .75s;
    animation-fill-mode: backwards;
  }

}

.btn-text {
  &:link,
  &:visited {
    font-size: $default-font-size;
    color: $color-primary;
    display: inline-block;
    text-decoration: none;
    border-bottom: 1px solid $color-primary;
    padding: 3px;
    transition: all .2s;
  }

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 1rem 2rem rgba($color-black, .2);
    background-color: $color-primary;
    color: $color-white;

  }

  &:active {
    transform: translateY(-1px);
    box-shadow: 0 .5rem 1rem rgba($color-black, .2);
  }
}