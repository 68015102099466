@import "../abstracts/variables";
@import "../abstracts/mixins";

body {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  line-height: 1.7;
  color: $color-grey-dark-1;
}

// Heading Primary Element

.heading-primary {
  color: $color-white;
  text-transform: uppercase;
  backface-visibility: hidden;
  margin-bottom: 6rem;

  &--main {
    display: block;
    font-size: 6rem;
    font-weight: 400;
    letter-spacing: 3.5rem;
    animation-name: moveInLeft;
    animation-duration: .8s;
    animation-timing-function: ease-out;

    @include respond(phone) {
      letter-spacing: 1rem;
      font-size: 5rem;
    };
  }

  &--sub {
    display: block;
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: 1.75rem;
    animation-name: moveInRight;
    animation-duration: .8s;

    @include respond(phone) {
      letter-spacing: 1rem;
    };
  }
}

// Heading Secondary Element

.heading-secondary {
  font-size: 3.5rem;
  text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
  background: linear-gradient(to right, $color-primary-light, $color-primary-dark);
  -webkit-background-clip: text;
  color: transparent;
  letter-spacing: 2px;
  transition: all .2s;

  &:hover {
    transform: skewY(-2deg) skewX(3deg) scale(1.1);
    text-shadow: .5rem 1rem 2rem rgba($color-black, .2);
  }

  @include respond(tab-port) {
    font-size: 2.7rem;
  };

  @include respond(phone) {
    font-size: 2rem;
  };
}

// Heading Tertiary Element

.heading-tertiary {
  font-size: $default-font-size;
  text-transform: uppercase;
  font-weight: 700;
}

.paragraph {
  font-size: $default-font-size;

  &:not(:last-child) {
    margin-bottom: 3rem;
  }
}