@import "../abstracts/variables";
@import "../abstracts/mixins";

.composition {
  position: relative;

  &__photo {
    width: 55%;
    box-shadow: 0 1.5rem 4rem rgba($color-black, .4);
    position: absolute;
    transition: all .2s;
    z-index: 10;
    //outline-offset: 2rem;

    @include respond(tab-port) {
      float: left;
      position: relative;
      width: 33.3%;
      box-shadow: 0 1.5rem 3rem rgba($color-black, .2);
    }

    &--p1 {
      top: -2rem;
      left: 0;

      @include respond(tab-port) {
        top: 0;
        transform: scale(1.2);
      }
    }

    &--p2 {
      top: 2rem;
      right: 0;

      @include respond(tab-port) {
        top: -2rem;
        transform: scale(1.3);
        z-index: 11;
      }
    }

    &--p3 {
      top: 10rem;
      left: 20%;

      @include respond(tab-port) {
        top: 1rem;
        left: 0;
        transform: scale(1.1);
      }
    }

    &:hover {
      //outline: 1px solid $color-primary;
      transform: scale(1.2) translateY(-.5rem);
      box-shadow: 0 2.5rem 4rem rgba($color-black, .4);
      z-index: 20;
    }
  }

  &:hover &__photo:not(:hover) {
    transform: scale(.85);
    opacity: .3;
  }

}