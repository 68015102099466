@import "../abstracts/variables";
@import "../abstracts/mixins";

.card {
  // Functionality
  height: 52rem;
  perspective: 150rem;
  -moz-perspective: 150rem;
  position: relative;

  &__side {
    height: 52rem;
    transition: all 0.8s ease;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    border-radius: 3px;
    overflow: hidden;

    &--front {
      background-color: $color-white;
    }

    &--back {
      transform: rotateY(180deg);

      &--1 {
        background-image: linear-gradient(
          to right bottom,
          $color-secondary-light,
          $color-secondary-dark
        );
      }

      &--2 {
        background-image: linear-gradient(
          to right bottom,
          $color-primary-light,
          $color-primary-dark
        );
      }

      &--3 {
        background-image: linear-gradient(
          to right bottom,
          $color-tertiary-light,
          $color-tertiary-dark
        );
      }

      &--4 {
        background-image: linear-gradient(
          to right bottom,
          $color-secondary-light,
          $color-secondary-dark
        );
      }

      &--5 {
        background-image: linear-gradient(
          to right bottom,
          $color-primary-light,
          $color-primary-dark
        );
      }

      &--6 {
        background-image: linear-gradient(
          to right bottom,
          $color-tertiary-light,
          $color-tertiary-dark
        );
      }
    }
  }

  // &:hover &__side--front {
  //   transform: rotateY(-180deg);
  // }

  // &:hover &__side--back {
  //   transform: rotateY(0);
  // }

  // FRONT SIDE STYLING
  &__picture {
    background-size: cover;
    height: 23rem;
    background-blend-mode: screen;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;

    &--1 {
      background-image: linear-gradient(
          to right bottom,
          rgba($color-primary-light, 0.1),
          rgba($color-primary-light, 0.1)
        ),
        url(../../img/fruit-basket.png);
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
      clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    }

    &--2 {
      background-image: linear-gradient(
          to right bottom,
          rgba($color-primary-light, 0.1),
          rgba($color-primary-light, 0.1)
        ),
        url(../../img/vegetable-basket.png);
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
      clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    }

    &--3 {
      background-image: linear-gradient(
          to right bottom,
          rgba($color-primary-light, 0.1),
          rgba($color-primary-light, 0.1)
        ),
        url(../../img/broccoli-min.jpeg);
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
      clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    }

    &--4 {
      background-image: linear-gradient(
          to right bottom,
          rgba($color-primary-light, 0.1),
          rgba($color-primary-light, 0.1)
        ),
        url(../../img/peeled-pomo.png);
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
      clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    }

    &--5 {
      background-image: linear-gradient(
          to right bottom,
          rgba($color-primary-light, 0.1),
          rgba($color-primary-light, 0.1)
        ),
        url(../../img/bread.jpeg);
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
      clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    }

    &--6 {
      background-image: linear-gradient(
          to right bottom,
          rgba($color-primary-light, 0.1),
          rgba($color-primary-light, 0.1)
        ),
        url(../../img/organic.jpeg);
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
      clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    }
  }

  &__heading {
    font-size: 2.8rem;
    font-weight: 300;
    text-align: right;
    text-transform: uppercase;
    color: $color-white;
    position: absolute;
    top: 12rem;
    right: 2rem;
    width: 75%;
    z-index: 1;
  }

  &__heading-span {
    padding: 1rem 1.5rem;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;

    &--1 {
      background-image: linear-gradient(
        to right bottom,
        rgba($color-secondary-light, 0.85),
        rgba($color-secondary-dark, 0.85)
      );
    }

    &--2 {
      background-image: linear-gradient(
        to right bottom,
        rgba($color-primary-light, 0.85),
        rgba($color-primary-dark, 0.85)
      );
    }

    &--3 {
      background-image: linear-gradient(
        to right bottom,
        rgba($color-secondary-light, 0.55),
        rgba($color-secondary-light, 0.75)
      );
    }
  }

  &__details {
    padding: 3rem;
    ul {
      list-style: none;
      width: 80%;
      margin: 0 auto;

      li {
        text-align: center;
        font-size: 1.5rem;
        padding: 1rem;
      }

      li:not(:last-child) {
        border-bottom: 1px solid $color-grey-light-2;
      }
    }
  }

  // BACK SIDE STYLING
  &__cta {
    @include center-element;

    text-align: center;
    width: 90%;
  }

  &__price-box {
    text-align: center;
    margin-bottom: 8rem;
    color: $color-white;
  }

  &__price-only {
    font-size: 1.4rem;
    text-transform: uppercase;
  }

  &__price-value {
    font-size: 6rem;
    font-weight: 100;
  }

  //@include respond(tab-port) {
  @media only screen and (max-width: 56.25em), only screen and (hover: none) {
    // Functionality
    height: auto;
    border-radius: 3px;
    background-color: $color-white;
    box-shadow: 0 1.5rem 4rem rgba($color-black, 0.2);

    &__side {
      height: auto;
      position: relative;
      box-shadow: none;

      &--back {
        transform: rotateY(0);
        clip-path: polygon(0 15%, 100% 0, 100% 100%, 0 100%);
        -webkit-clip-path: polygon(0 15%, 100% 0, 100% 100%, 0 100%);
      }
    }

    &:hover &__side--front {
      transform: rotateY(0);
    }

    &__details {
      padding: 4rem 3rem;
    }

    // BACK SIDE STYLING
    &__cta {
      position: relative;
      top: 0;
      left: 0;
      transform: translate(0);
      width: 100%;
      padding: 7rem 4rem 4rem;
    }

    &__price-box {
      margin-bottom: 3rem;
    }

    &__price-value {
      font-size: 4rem;
    }
  }
}
