@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem)
  }
  80% {
    opacity: .8;
    transform: translateX(1rem);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem)
  }
  80% {
    opacity: .8;
    transform: translateX(-1rem);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}


@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(4rem)
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}