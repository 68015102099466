@import "../abstracts/variables";

.bg-video {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -10;
  opacity: .2;
  overflow: hidden;

  &__content {
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
}