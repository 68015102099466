@import "../abstracts/variables";
@import "../abstracts/mixins";

.row {
  // If the width is smaller than grid-width, then it will simply occupy 100% of its width;
  max-width: $grid-width;
  margin: 0 auto;

  &:not(:last-child) {
    margin-bottom: $gutter-vertical;

    @include respond(tab-port) {
      margin-bottom: $gutter-vertical-sm;
    }

    @include respond(tab-port) {
      margin-bottom: $gutter-vertical-xs;
    }
  }

  @include respond(tab-port) {
    max-width: 50rem;
  }

  @include clearfix;

  [class^="col-"] {
    float: left;
    color: $color-grey-dark-1;
    padding: 1rem;

    &:not(:last-child) {
      margin-right: $gutter-horizontal;

      @include respond(tab-port) {
        margin-right: 0;
        margin-bottom: $gutter-vertical-xs;
      }

      @include respond(phone) {
        margin-right: 0;
        margin-bottom: $gutter-vertical-xs;
      }
    }

    @include respond(tab-port) {
      width: 100% !important;
    }
  }

  .col-1-of-2 {
    width: calc((100% - #{$gutter-horizontal}) / 2);
  }

  .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal}) / 3);
  }

  .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal}) / 4);
  }

  .col-2-of-3 {
    width: calc(2 * ((100% - 2 * #{$gutter-horizontal}) / 3) + #{$gutter-horizontal});
  }

  .col-2-of-4 {
    width: calc(2 * ((100% - 3 * #{$gutter-horizontal}) / 4) + #{$gutter-horizontal});
  }

  .col-3-of-4 {
    width: calc(3 * ((100% - 3 * #{$gutter-horizontal}) / 4) + 2 * #{$gutter-horizontal});
  }
}