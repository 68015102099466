@import "../abstracts/variables";
@import "../abstracts/mixins";

.header {
  height: 85vh;
  background-size: cover;
  background: linear-gradient(
      to right bottom,
      rgba($color-primary-light, 0.8),
      rgba($color-primary-dark, 0.8)
    ),
    url(../../img/nat-1.jpg) top;
  position: relative;

  @supports (clip-path: polygon(0 0, 0 0, 0 0, 0 0)) or
    (-webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0)) {
    clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);
    height: 95vh;
  }

  @media only screen and (min-resolution: 192dpi) and (min-width: 37.5em),
    (min-width: 75em),
    (-webkit-min-device-pixel-ratio: 2) and (min-width: 37.5em) {
    background: linear-gradient(
        to right bottom,
        rgba($color-primary-light, 0.4),
        rgba($color-primary-dark, 0.4)
      ),
      url(../../img/nat-1.jpg) top no-repeat;
    background-size: cover;
  }

  @include respond(phone) {
    clip-path: polygon(0 0, 100% 0, 100% 85vh, 0 100%);
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 85vh, 0 100%);
  }

  &__logo-box {
    position: absolute;
    top: 2rem;
    left: 2rem;
  }

  &__logo {
    height: 10.5rem;
  }

  &__text-box {
    position: absolute;
    top: 42%;
    width: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
}

.btn--ios {
  margin-right: 1rem;
}

.store-icons {
  height: 2rem;
  transform: translateY(2px);
  padding-right: 1rem;
}
