@import "../abstracts/mixins";

.u-center-text {
  text-align: center;
}

.u-margin-bottom-big {
  margin-bottom: 8rem;

  @include respond(tab-port) {
    margin-bottom: 6rem;
  }
}

.u-margin-bottom-med {
  margin-bottom: 4rem;
}

.u-margin-bottom-small {
  margin-bottom: 1.5rem;
}

.u-margin-top-small {
  margin-top: 4rem;
}

.u-margin-top-big {
  margin-top: 8rem;
}

.u-margin-top-huge {
  margin-top: 10rem;
}